<div class="dropdown-container" (clickOutside)="showDropdownContent = false">
    <div class="row justify-content-end exp-dropdown">
        <span class="title">{{ title }}: </span>

        <div class="selected" (click)="showDropdownContent = !showDropdownContent">
            {{ selectedValue.value }}
            <span [ngClass]="{ show: showDropdownContent === true }" class="select-icon"><fa-icon [icon]="faAngleDown"></fa-icon></span>
            <span [ngClass]="{ show: showDropdownContent === false }" class="select-icon"><fa-icon [icon]="faAngleUp"></fa-icon></span>
        </div>
    </div>

    <div *ngIf="showDropdownContent" class="row justify-content-end exp-dropdown menu">
        <ul>
            <li *ngFor="let value of values" (click)="valueSelected(value)">{{ value.key }}</li>
        </ul>
    </div>
</div>
