<nav class="navmenu col-12 remove-padding">
    <div class="red-bar"></div>

    <div class="row navbar-header align-items-center remove-margin">
        <div class="offset-1 offset-xl-2 col-2 remove-padding">
            <a class="navbar-brand" [routerLink]="['/home']">
                <img id="brand-img" src="../../../assets/logo.png" alt="Expoints logo" class="brand-img" />
            </a>
        </div>

        <div class="col-8 col-xl-6 nav-base">
            <div class="nav-desktop">
                <ng-container *ngFor="let navItem of navItems">
                    <div *ngIf="navItem.visible && navItem.children.length === 0" class="nav-item">
                        <a [routerLink]="[navItem.link]" routerLinkActive="active-link">{{ navItem.name }}</a>
                    </div>

                    <div *ngIf="navItem.visible && navItem.children.length !== 0" class="nav-item">
                        {{ navItem.name }}
                        <div class="dropdown-container" *ngIf="navItem.children !== undefined">
                            <div class="dropdown-content">
                                <ng-container *ngFor="let chiledNavItem of navItem.children">
                                    <a *ngIf="chiledNavItem.visible" [routerLink]="[chiledNavItem.link]" routerLinkActive="active-link">{{
                                        chiledNavItem.name
                                    }}</a>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div class="nav-mobile" (clickOutside)="isCollapsed = true">
                <div class="nav-button" (click)="isCollapsed = !isCollapsed">
                    <fa-icon class="navbar-menu-icon" [icon]="faBarsIcon"></fa-icon>
                </div>

                <div class="nav-small-dropdown" *ngIf="!isCollapsed">
                    <ul class="nav navbar-nav navbar-right">
                        <li class="list-bar"></li>

                        <ng-container *ngFor="let navItem of navItems">
                            <li
                                *ngIf="navItem.visible"
                                [routerLink]="[navItem.link]"
                                routerLinkActive="active-link"
                                class="mobile-nav-link"
                                (click)="isCollapsed = true"
                            >
                                {{ navItem.name }}
                                <span><fa-icon class="navbar-li-icon" [icon]="faChevronLeft"></fa-icon></span>
                            </li>

                            <ng-container *ngFor="let chiledNavItem of navItem.children">
                                <li
                                    *ngIf="chiledNavItem.visible"
                                    [routerLink]="[chiledNavItem.link]"
                                    routerLinkActive="active-link"
                                    class="mobile-nav-link"
                                    (click)="isCollapsed = true"
                                >
                                    {{ chiledNavItem.name }}
                                    <span><fa-icon class="navbar-li-icon" [icon]="faChevronLeft"></fa-icon></span>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>
