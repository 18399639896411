<div class="stars-container">
    <div class="stars" [ngClass]="{ small: size === 'small', big: size === 'big', medium: size === 'medium' }">
        <div class="stars-base">
            <fa-icon [icon]="faStar"></fa-icon>
            <fa-icon [icon]="faStar"></fa-icon>
            <fa-icon [icon]="faStar"></fa-icon>
            <fa-icon [icon]="faStar"></fa-icon>
            <fa-icon [icon]="faStar"></fa-icon>

            <div class="stars-overlay" [ngStyle]="{ width: ratingPercentage }">
                <fa-icon [icon]="faStar"></fa-icon>
                <fa-icon [icon]="faStar"></fa-icon>
                <fa-icon [icon]="faStar"></fa-icon>
                <fa-icon [icon]="faStar"></fa-icon>
                <fa-icon [icon]="faStar"></fa-icon>
            </div>
        </div>

        <div class="stars-rating" itemprop="ratingValue">{{ rating | number: '0.0-1' }}<span *ngIf="size !== 'small'">/10</span></div>
    </div>
</div>
