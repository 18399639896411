import { WidgetSetting } from './widget-setting.model';
import { WidgetTypeEnum } from './widget-type-enum.model';

export class CreateOrUpdateWidgetRequest {
    public key?: string;
    public name: string;
    public dataId: number;
    public description: string;
    public type: WidgetTypeEnum;
    public widgetSettings: WidgetSetting[];
}
