<div class="row remove-margin">
    <div class="col-12 remove-padding filter-titel" (click)="sortFiltersCollapsed = !sortFiltersCollapsed">
        SORTEER OPTIES
        <span *ngIf="!sortFiltersCollapsed" class="icon"><fa-icon [icon]="faAngleDown"></fa-icon></span>
        <span *ngIf="sortFiltersCollapsed" class="icon"><fa-icon [icon]="faAngleUp"></fa-icon></span>
    </div>
</div>

<ng-container *ngIf="!sortFiltersCollapsed">
    <div *ngFor="let sortOption of sortOptions">
        <input
            class="form-check-input radio"
            type="radio"
            id="{{ sortOption.name }}"
            [value]="sortOption.type"
            name="sortOption"
            [(ngModel)]="selectedSortOption"
            (click)="updateSortOptions(sortOption.type)"
        />
        <label class="form-check-label" for="{{ sortOption.name }}">{{ sortOption.name }}</label>
    </div>
</ng-container>
