export class GetWidgetsRequest {
    public searchTerm: string;
    public itemsPerPage: number;
    public page: number;

    constructor(searchTerm: string, itemsPerPage: number, page: number) {
        this.searchTerm = searchTerm;
        this.itemsPerPage = itemsPerPage;
        this.page = page;
    }
}
