import { Subject } from 'rxjs';

import { Component, OnInit } from '@angular/core';

import { IconDefinition, faBan, faCheck } from '@fortawesome/free-solid-svg-icons';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-confirmation-modal',
    styleUrls: ['./confirmation-modal.component.scss'],
    templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent implements OnInit {
    public faBan: IconDefinition = faBan;
    public faCheck: IconDefinition = faCheck;
    public title: string;
    public question: string;
    public answerNegative: string;
    public answerPositive: string;
    public closeBtnName: string;
    public showDeleteUserError = false;
    public onClose: Subject<boolean>;

    constructor(public bsModalRef: BsModalRef) {}

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    public confirm(): void {
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    public decline(): void {
        this.onClose.next(false);
        this.bsModalRef.hide();
    }
}
