<div class="filter-container {{ device }}" (clickOutside)="closeMobileMenu($event)">
    <div class="filter-menu-open-button {{ device }}" *ngIf="device === 'mobile'" (click)="openMobileMenu($event)">
        <div class="d-flex flex-fill justify-content-end grid-button-container margin-right-15">
            <div class="d-flex justify-content-center align-self-center button small blue">
                <div class="button-content align-self-center">
                    <span class="icon"><fa-icon [icon]="faFilter"></fa-icon></span>FILTER
                </div>
            </div>
        </div>
    </div>

    <div class="filter-menu {{ device }}" [ngClass]="{ show: showMobileMenu }">
        <ng-container *ngIf="config.dataFilter">
            <app-data-filter [dataFiltersCollapsed]="!config.dataFilterOpen"></app-data-filter>
        </ng-container>

        <ng-container *ngIf="config.sortOptions">
            <app-sort-filter [sortFiltersCollapsed]="!config.sortOptionsOpen" [defaultValue]="config.sortOptionDefault"></app-sort-filter>
        </ng-container>

        <ng-container *ngIf="config.textSearch">
            <app-text-filter [textFiltersCollapsed]="!config.textSearchOpen"></app-text-filter>
        </ng-container>

        <ng-container *ngIf="config.dateSearch">
            <app-date-filter [dateFiltersCollapsed]="!config.dateSearchOpen"></app-date-filter>
        </ng-container>

        <ng-container *ngIf="config.statusOptions">
            <app-status-filter
                [statusFiltersCollapsed]="!config.statusOptionsOpen"
                [defaultValue]="config.statusOptionDefault"
                [statusOptionsWhitelist]="config.statusOptionWhitelist"
            ></app-status-filter>
        </ng-container>
    </div>
</div>
