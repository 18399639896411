import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import * as dataViewTypeEnum from '../../models/data-view-type.enum';
import { Data } from '../../models/data.model';

@Component({
    selector: 'app-company-block',
    templateUrl: './company-block.component.html',
    styleUrls: ['./company-block.component.scss']
})
export class CompanyBlockComponent implements OnInit {
    @Input() public dataInput: Data;
    @Input() public class: string;

    public data: Data;
    public dataViewType = dataViewTypeEnum.DataViewType;

    constructor(
        public translate: TranslateService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.data = this.dataInput;
    }
}
