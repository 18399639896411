export class GetDataListRequest {
    public searchTerm: string;
    public itemsPerPage: number;
    public page: number;
    public filterByLevel: boolean;
    public parentId?: number;

    constructor(searchTerm: string, itemsPerPage: number, page: number, filterByLevel: boolean, parentId?: number) {
        this.searchTerm = searchTerm;
        this.itemsPerPage = itemsPerPage;
        this.page = page;
        this.filterByLevel = filterByLevel;
        this.parentId = parentId;
    }
}
