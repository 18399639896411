import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';

import { Utilities } from './utilities.service';

@Injectable()
export class HttpRedirectInterceptor implements HttpInterceptor {
    private httpCounter = new BehaviorSubject(0);

    constructor(
        private authService: MsalService,
        private router: Router,
        private utils: Utilities
    ) {
        this.subscribeToHttpCounter();
    }

    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const isUrlException = this.isUrlException(request.url);

        if (!isUrlException) {
            this.incrementPendingRequests();
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status == 401) {
                    this.authService.loginRedirect();
                }
                if (error.status == 403) {
                    this.router.navigateByUrl('/error');
                }
                return throwError(() => error);
            }),
            finalize(() => {
                if (!isUrlException) {
                    this.decrementPendingRequests();
                }
            })
        );
    }

    private subscribeToHttpCounter(): void {
        this.httpCounter.subscribe((counter) => {
            const isLoading: boolean = counter > 0;
            this.utils.setShowSpinner(isLoading);
        });
    }

    private incrementPendingRequests(): void {
        const newValue = this.httpCounter.value + 1;
        this.httpCounter.next(newValue);
    }

    private decrementPendingRequests(): void {
        const newValue = this.httpCounter.value - 1;
        this.httpCounter.next(newValue);
    }

    private isUrlException(url: string): boolean {
        const exceptions: string[] = ['./api/WidgetManagement/PreviewWidget'];

        return exceptions.includes(url);
    }
}
