import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApprovalOverview } from '../../models/approval-overview.model';
import { GetManagementReviewsRequest } from '../../models/get-management-reviews-request.model';
import { GetReviewByDataFilterResponse } from '../../models/get-review-by-data-filter-response.model';
import { GetReviewByFilterResponse } from '../../models/get-review-by-filter-response.model';
import { GetReviewsRequest } from '../../models/get-review-request.model';
import { ReviewStatusEnum } from '../../models/review-status.enum';
import { Review } from '../../models/review.model';
import { UpdateReviewStatusRequest } from '../../models/update-review-status-request.model';
import { UpdateReviewStatussenRequest } from '../../models/update-review-statussen-request.model';

@Injectable()
export class ReviewService {
    private debounceTimeInMilliseconds = 1000;
    private baseUrl: string;
    private reviews = new BehaviorSubject(new Array<Review>());
    private followingReviewsCount = new BehaviorSubject(0);
    private getReviewsRequest = new BehaviorSubject(new GetReviewsRequest());
    private getManagementReviewsRequest = new BehaviorSubject(new GetManagementReviewsRequest());
    private getManagementReviewsResponse = new BehaviorSubject(new GetReviewByFilterResponse());
    private approvalOverview = new BehaviorSubject(new ApprovalOverview());

    constructor(private http: HttpClient) {
        this.baseUrl = '/';
        this.subscribeToGetReviewsRequest();
        this.subscribeTogetManagementReviewsRequest();
    }

    public updateGetReviewRequest(getReviewsRequest: GetReviewsRequest): void {
        this.getReviewsRequest.next(getReviewsRequest);
    }

    public getReviews(): BehaviorSubject<Review[]> {
        return this.reviews;
    }

    public getReviewLeft(): BehaviorSubject<number> {
        return this.followingReviewsCount;
    }

    public getLastReviewsRequest(): GetReviewsRequest {
        return this.getReviewsRequest.value;
    }

    public getReviewsRequestSubscription(): BehaviorSubject<GetReviewsRequest> {
        return this.getReviewsRequest;
    }

    public getManagementReviewsResponseSubscription(): BehaviorSubject<GetReviewByFilterResponse> {
        return this.getManagementReviewsResponse;
    }

    public getManagementReviewsRequestValue(): GetManagementReviewsRequest {
        return this.getManagementReviewsRequest.value;
    }

    public getManagementReviewsRequestObservable(): BehaviorSubject<GetManagementReviewsRequest> {
        return this.getManagementReviewsRequest;
    }

    public setGetManagementReviewsRequest(getManagementReviewsRequest: GetManagementReviewsRequest): void {
        this.getManagementReviewsRequest.next(getManagementReviewsRequest);
    }

    public setManagementReviewResponse(getReviewByFilterResponse: GetReviewByFilterResponse): void {
        this.getManagementReviewsResponse.next(getReviewByFilterResponse);
    }

    public updateReviewStatussen(updateReviewStatussenRequest: UpdateReviewStatussenRequest): void {
        const midUrl = 'api/ReviewManagement/UpdateReviewStatuses';
        const url = `${this.baseUrl}${midUrl}`;

        this.http.post(url, updateReviewStatussenRequest).subscribe(() => {
            this.refreshManagementReviews();
        });
    }

    public getApprovalOverviewObservable(): BehaviorSubject<ApprovalOverview> {
        return this.approvalOverview;
    }

    public setApprovalOverview(approvalOverview: ApprovalOverview): void {
        this.approvalOverview.next(approvalOverview);
    }

    public mapGetManagementReviewsResponseToUpdateReviewStatussenRequest(): UpdateReviewStatussenRequest {
        const updateReviewStatussenRequest = new UpdateReviewStatussenRequest();
        const getManagementReviewsResponse = this.getManagementReviewsResponse.value;

        getManagementReviewsResponse.reviewViewModels.forEach((review) => {
            if (review.currentStatus != ReviewStatusEnum.Pending) {
                const updateReviewStatusRequest: UpdateReviewStatusRequest = new UpdateReviewStatusRequest();
                updateReviewStatusRequest.reviewId = review.id;
                updateReviewStatusRequest.status = review.currentStatus;
                updateReviewStatussenRequest.updateReviewStatusRequests.push(updateReviewStatusRequest);
            }
        });

        return updateReviewStatussenRequest;
    }

    private getReviewsByDataId(getReviewsRequest: GetReviewsRequest): void {
        const midUrl = 'api/Review/GetReviewsByDataFilter';
        const url = `${this.baseUrl}${midUrl}`;
        const options = this.createParametersForGetReviewsByDataId(getReviewsRequest);

        this.http.get<GetReviewByDataFilterResponse>(url, { params: options }).subscribe((getReviewByDataFilterResponse) => {
            if (!getReviewsRequest.renew) {
                const reviewArray: Review[] = this.reviews.value;

                getReviewByDataFilterResponse.reviewViewModels.map((review) => {
                    reviewArray.push(review);
                });

                this.reviews.next(reviewArray);
                this.followingReviewsCount.next(getReviewByDataFilterResponse.followingReviewsCount);
            } else {
                this.reviews.next(getReviewByDataFilterResponse.reviewViewModels);
                this.followingReviewsCount.next(getReviewByDataFilterResponse.followingReviewsCount);
            }
        });
    }

    private subscribeToGetReviewsRequest(): void {
        this.getReviewsRequest.subscribe((getReviewRequest) => {
            if (getReviewRequest.dataIds.length != 0) {
                this.getReviewsByDataId(getReviewRequest);
            }
        });
    }

    private getReviewsForManagementView(getManagementReviewsRequest: GetManagementReviewsRequest): void {
        const midUrl = 'api/ReviewManagement/GetReviews';
        const options = this.createParametersForGetReviewsForManagementView(getManagementReviewsRequest);
        const url = `${this.baseUrl}${midUrl}`;

        this.http.get<GetReviewByFilterResponse>(url, { params: options }).subscribe((getReviewByFilterResponse) => {
            this.getManagementReviewsResponse.next(getReviewByFilterResponse);
        });
    }

    private subscribeTogetManagementReviewsRequest(): void {
        this.getManagementReviewsRequest.pipe(debounceTime(this.debounceTimeInMilliseconds)).subscribe((getManagementReviewsRequest) => {
            if (getManagementReviewsRequest.page <= 0 || getManagementReviewsRequest.passive === true) {
                return;
            }

            this.getReviewsForManagementView(getManagementReviewsRequest);
        });
    }

    private createParametersForGetReviewsByDataId(getReviewsRequest: GetReviewsRequest): HttpParams {
        let httpParams = new HttpParams();

        getReviewsRequest.dataIds.forEach((id) => {
            httpParams = httpParams.append('dataIds', id.toString());
        });

        if (getReviewsRequest.subValue !== null && getReviewsRequest.subValue !== undefined) {
            httpParams = httpParams.append('subValue', getReviewsRequest.subValue);
        }

        if (getReviewsRequest.skip !== null && getReviewsRequest.skip !== undefined) {
            httpParams = httpParams.append('skip', getReviewsRequest.skip.toString());
        }

        if (getReviewsRequest.take !== null && getReviewsRequest.take !== undefined) {
            httpParams = httpParams.append('take', getReviewsRequest.take.toString());
        }

        return httpParams;
    }

    private createParametersForGetReviewsForManagementView(requestObject: GetManagementReviewsRequest): HttpParams {
        let httpParams = new HttpParams();

        requestObject.dataIds.forEach((id) => {
            httpParams = httpParams.append('dataIds', id.toString());
        });

        if (requestObject.fromDate !== null && requestObject.fromDate !== undefined) {
            httpParams = httpParams.append('fromDate', requestObject.fromDate.toISOString());
        }

        if (requestObject.toDate !== null && requestObject.toDate !== undefined) {
            httpParams = httpParams.append('toDate', requestObject.toDate.toISOString());
        }

        if (requestObject.itemsPerPage !== null && requestObject.itemsPerPage !== undefined) {
            httpParams = httpParams.append('itemsPerPage', requestObject.itemsPerPage.toString());
        }

        if (requestObject.page !== null && requestObject.page !== undefined) {
            httpParams = httpParams.append('page', requestObject.page.toString());
        }

        if (requestObject.searchTerm !== null && requestObject.searchTerm !== undefined && requestObject.searchTerm !== '') {
            httpParams = httpParams.append('searchTerm', requestObject.searchTerm);
        }

        if (requestObject.sortType !== null && requestObject.sortType !== undefined) {
            httpParams = httpParams.append('sortType', requestObject.sortType.toString());
        }

        if (requestObject.status !== null && requestObject.status !== undefined) {
            httpParams = httpParams.append('status', requestObject.status.toString());
        }

        return httpParams;
    }

    private refreshManagementReviews(): void {
        const getManagementReviewsRequestValue = this.getManagementReviewsRequestValue();
        getManagementReviewsRequestValue.page = 1;
        this.setGetManagementReviewsRequest(getManagementReviewsRequestValue);
    }
}
