import { filter } from 'rxjs';

import { Component, OnInit } from '@angular/core';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { IconDefinition, faBars, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { NavigationInformation } from '../../models/navigation-info.model';
import { UserService } from '../../shared/services/user.service';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
    public isCollapsed = true;
    public faBarsIcon: IconDefinition = faBars;
    public faChevronLeft: IconDefinition = faChevronLeft;
    public managementTitle = 'Beheer';

    public navItems: NavigationInformation[] = [
        { link: '/home', name: 'Home', visible: true, children: [] },
        { link: '/reviews', name: 'Reviews', visible: true, children: [] },
        { link: '/kwaliteit', name: 'Kwaliteit', visible: true, children: [] },
        { link: '/contact', name: 'Contact', visible: true, children: [] },
        { link: '/faq', name: 'FAQ', visible: true, children: [] },
        { link: '', name: 'Beheer', visible: false, children: [] }
    ];

    constructor(
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private userService: UserService
    ) {}

    public ngOnInit(): void {
        this.checkLoggedIn();
        this.subscribeToManagementMenu();
    }

    public checkLoggedIn(): void {
        this.msalBroadcastService.msalSubject$
            .pipe(filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END))
            .subscribe(() => {
                if (this.authService.instance.getAllAccounts().length > 0) {
                    const beheerMenuItem = this.navItems.filter((x) => {
                        return x.name == this.managementTitle;
                    })[0];
                    beheerMenuItem.visible = true;
                    this.userService.getManagementMenuHttpCall();
                }
            });
    }

    public subscribeToManagementMenu(): void {
        this.userService.getManagementMenu().subscribe((managementMenu) => {
            const beheerMenuItem = this.navItems.filter((x) => {
                return x.name == this.managementTitle;
            })[0];

            beheerMenuItem.children = managementMenu;
        });
    }
}
