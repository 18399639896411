<div class="container-fluid remove-padding app-wrapper">
    <div class="row remove-margin">
        <div class="col-12 remove-padding">
            <app-nav-menu></app-nav-menu>

            <router-outlet *ngIf="!isIframe"></router-outlet>

            <app-footer class="app-footer"></app-footer>

            <ngx-spinner
                bdOpacity="0.4"
                bdColor="rgba(255,255,255,0.4)"
                size="large"
                color="#f04201"
                type="ball-square-spin"
                fullScreen="true"
                name="main"
            >
                <p class="spinner-text">Laden...</p>
            </ngx-spinner>
        </div>
    </div>
</div>
