import { Component, Input } from '@angular/core';

import { IconDefinition, faAngleDown, faAngleUp, faSearch } from '@fortawesome/free-solid-svg-icons';

import { ReviewService } from '../../../shared/services/review.service';

@Component({
    selector: 'app-text-filter',
    styleUrls: ['./text-filter.component.scss'],
    templateUrl: './text-filter.component.html'
})
export class TextFilterComponent {
    @Input() public textFiltersCollapsed: boolean;

    public faAngleDown: IconDefinition = faAngleDown;
    public faAngleUp: IconDefinition = faAngleUp;
    public faSearch: IconDefinition = faSearch;

    public searchTerm = '';

    constructor(private reviewService: ReviewService) {}

    public searchonInput(): void {
        const getReviewRequest = this.reviewService.getManagementReviewsRequestValue();

        getReviewRequest.searchTerm = this.searchTerm;
        getReviewRequest.page = 1;
        getReviewRequest.passive = false;

        this.reviewService.setGetManagementReviewsRequest(getReviewRequest);
    }
}
