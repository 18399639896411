<div class="row remove-margin">
    <div class="col-12 remove-padding filter-titel" (click)="textFiltersCollapsed = !textFiltersCollapsed">
        TEKST ZOEKEN
        <span *ngIf="!textFiltersCollapsed" class="icon"><fa-icon [icon]="faAngleDown"></fa-icon></span>
        <span *ngIf="textFiltersCollapsed" class="icon"><fa-icon [icon]="faAngleUp"></fa-icon></span>
    </div>
</div>

<ng-container *ngIf="!textFiltersCollapsed">
    <div class="remove-margin search-container">
        <div class="col-12 remove-padding">
            <div class="search-field">
                <input type="text" [(ngModel)]="searchTerm" (keyup)="searchonInput()" />
                <span class="icon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
        </div>
    </div>
</ng-container>
