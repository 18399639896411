import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
    },
    {
        path: 'reviews',
        loadChildren: () => import('./clients/clients.module').then((m) => m.ClientsModule)
    },
    {
        path: 'kwaliteit',
        loadChildren: () => import('./quality/quality.module').then((m) => m.QualityModule)
    },
    {
        path: 'faq',
        loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule)
    },
    {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule)
    },
    {
        path: 'widgets-documentation',
        loadChildren: () => import('./widgets-documentation/widgets-documentation.module').then((m) => m.WidgetsDocumentationModule)
    },
    {
        path: 'reviews-approval',
        loadChildren: () => import('./admin/review-approval/review-approval.module').then((m) => m.ReviewApprovalModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'user-management',
        loadChildren: () => import('./admin/user-management/user-management.module').then((m) => m.UserManagementModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'review-management',
        loadChildren: () => import('./admin/review-management/review-management.module').then((m) => m.ReviewManagementModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'data-management',
        loadChildren: () => import('./admin/data-management/data-management.module').then((m) => m.DataManagementModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'widget-management',
        loadChildren: () => import('./admin/widget-management/widget-management.module').then((m) => m.WidgetManagementModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'image-management',
        loadChildren: () => import('./admin/image-management/image-management.module').then((m) => m.ImageManagementModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'export',
        loadChildren: () => import('./admin/export-management/export-management.module').then((m) => m.ExportManagementModule),
        canActivate: [MsalGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
    },
    {
        path: 'logout',
        loadChildren: () => import('./logout/logout.module').then((m) => m.LogoutModule)
    },
    {
        path: 'error',
        loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule)
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/home',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
