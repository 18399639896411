export class GetManagementReviewsRequest {
    public dataIds: number[] = [];
    public itemsPerPage = 10;
    public page = 0;
    public status?: number = null;
    public sortType = 4;
    public searchTerm = '';
    public fromDate?: Date;
    public toDate?: Date;
    public passive = true;
}
