import { Component, Input } from '@angular/core';

import { IconDefinition, faAngleDown, faAngleUp, faBan, faSearch } from '@fortawesome/free-solid-svg-icons';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { nlLocale } from 'ngx-bootstrap/locale';

import { ReviewService } from '../../../shared/services/review.service';

defineLocale('nl', nlLocale);

@Component({
    selector: 'app-date-filter',
    styleUrls: ['./date-filter.component.scss'],
    templateUrl: './date-filter.component.html'
})
export class DateFilterComponent {
    @Input() public dateFiltersCollapsed: boolean;

    public faAngleDown: IconDefinition = faAngleDown;
    public faAngleUp: IconDefinition = faAngleUp;
    public faSearch: IconDefinition = faSearch;
    public faBan: IconDefinition = faBan;
    public bsConfig: Partial<BsDatepickerConfig> = {
        containerClass: 'theme-red smaller',
        dateInputFormat: 'DD/MM/YYYY'
    };
    public from: Date;
    public to: Date;

    constructor(
        private reviewService: ReviewService,
        private localeService: BsLocaleService
    ) {
        this.localeService.use('nl');
    }

    public onFromValueChange(value: Date): void {
        this.from = value;
        this.updateGetReviewRequest();
    }

    public onToValueChange(value: Date): void {
        this.to = value;
        this.updateGetReviewRequest();
    }

    public updateGetReviewRequest(): void {
        const getReviewRequest = this.reviewService.getManagementReviewsRequestValue();

        getReviewRequest.toDate = this.to;
        getReviewRequest.fromDate = this.from;
        getReviewRequest.page = 1;
        getReviewRequest.passive = false;

        this.reviewService.setGetManagementReviewsRequest(getReviewRequest);
    }

    public clearToDate(event: Event): void {
        event.stopPropagation();
        this.to = null;
        this.updateGetReviewRequest();
    }

    public clearFromDate(event: Event): void {
        event.stopPropagation();
        this.from = null;
        this.updateGetReviewRequest();
    }
}
