<div class="grid">
    <div class="row grid-header">
        <div class="d-flex grid-title-container">
            <div class="grid-title extra-padding-left-for-mobile align-self-center">
                Reviews <span *ngIf="currentReviewRequest.status === reviewStatusEnumType.Sanitized">(gemarkeerd als vulgair)</span>
            </div>
        </div>

        <div class="d-flex flex-fill justify-content-end rm-button-container">
            <div class="button extra-small red">
                <span title="refresh pagina" class="icon" (click)="refreshPage()"><fa-icon [icon]="faSync"></fa-icon></span>
            </div>
        </div>
    </div>

    <ng-container *ngFor="let review of getManagementReviewsResponse.reviewViewModels">
        <div class="row d-flex grid-row border-right-1">
            <div
                class="grid-row-col gb-color"
                [ngClass]="{
                    red: review.currentStatus === reviewStatusEnumType.Rejected,
                    blue: review.currentStatus === reviewStatusEnumType.Accepted,
                    neutral: review.currentStatus === reviewStatusEnumType.Pending
                }"
            ></div>

            <div class="col-11 col-md grid-row-col">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3">Waardering:</div>

                            <div class="col-9 col-md-7">
                                <app-stars
                                    [min]="review.minRating"
                                    [max]="review.maxRating"
                                    [rating]="review.rating"
                                    [size]="'medium'"
                                ></app-stars>
                            </div>

                            <div class="col-12 col-md-2">
                                <span
                                    class="float-right status-icon gb-color red"
                                    *ngIf="config.showStatusIcon === true && review.currentStatus === reviewStatusEnumType.Rejected"
                                    ><span class="icon"><fa-icon [icon]="faThumbsDown"></fa-icon></span
                                ></span>

                                <span
                                    class="float-right status-icon gb-color blue"
                                    *ngIf="config.showStatusIcon === true && review.currentStatus === reviewStatusEnumType.Accepted"
                                    ><span class="icon"><fa-icon [icon]="faThumbsUp"></fa-icon></span
                                ></span>

                                <span
                                    class="float-right status-icon gb-color neutral"
                                    *ngIf="config.showStatusIcon === true && review.currentStatus === reviewStatusEnumType.Pending"
                                    ><span class="icon"><fa-icon [icon]="faAdjust"></fa-icon></span
                                ></span>

                                <span class="float-right padding-top-5">{{ review.date | date: 'dd-MM-yyyy' }}</span>
                            </div>

                            <div class="col-3">Titel:</div>

                            <div class="col-9">
                                <h5>
                                    {{ review.sanitizedTitle ?? review.title }}
                                    <span class="unsanitized" *ngIf="review.sanitizedTitle">({{ review.title }})</span>
                                </h5>
                            </div>

                            <div class="col-3">Review:</div>

                            <div class="col-9">
                                <ng-container *ngFor="let data of review.reviewDescriptions">
                                    <h5 *ngIf="data.title !== ''">{{ data.title }}</h5>
                                    <p>
                                        {{ data.sanitizedText ?? data.text }}
                                        <span class="unsanitized" *ngIf="data.sanitizedText">({{ data.text }})</span>
                                    </p>
                                </ng-container>
                            </div>

                            <div class="col-3">Naam:</div>

                            <div class="col-9">
                                {{ review.sanitizedName ?? review.name }}
                                <span class="unsanitized" *ngIf="review.sanitizedName">({{ review.name }})</span>
                            </div>

                            <div class="col-3" *ngIf="review.reviewReactions.length > 0">Reacties:</div>

                            <div class="col-9" *ngIf="review.reviewReactions.length > 0">
                                <ng-container *ngFor="let reaction of review.reviewReactions">
                                    <h4 *ngIf="reaction.title !== ''">{{ reaction.title }}</h4>
                                    <p>{{ reaction.text }}</p>
                                </ng-container>
                            </div>

                            <div class="col-3">Data:</div>

                            <div class="col-9 margin-bottom-5">
                                <span
                                    *ngFor="let data of review.reviewData"
                                    class="badge badge-pill"
                                    [ngClass]="{ 'badge-danger': !data.data.active, 'badge-secondary': data.data.active }"
                                >
                                    {{ data.data.displayName }}
                                </span>
                            </div>

                            <div class="col-12 text-danger" *ngIf="anyNonActiveLevels(review.reviewData)">
                                Let op, een van de hierboven getoonde niveaus wordt (nog) niet getoond op de reviewsite.
                            </div>

                            <div class="col-12">
                                <div class="d-flex justify-content-end">
                                    <ng-container *ngIf="config.showRejectReviewButton">
                                        <div
                                            class="d-flex justify-content-center button small red margin-right-5"
                                            [ngClass]="{
                                                selected: review.currentStatus === reviewStatusEnumType.Rejected,
                                                'not-selected':
                                                    review.currentStatus !== reviewStatusEnumType.Rejected &&
                                                    review.currentStatus !== reviewStatusEnumType.Pending &&
                                                    review.currentStatus !== reviewStatusEnumType.Sanitized
                                            }"
                                            (click)="review.currentStatus = reviewStatusEnumType.Rejected; updatePendingNumbers()"
                                        >
                                            <div class="button-content align-self-center">
                                                <span class="icon"><fa-icon [icon]="faThumbsDown"></fa-icon></span
                                                ><span class="icon-text">AFKEUREN</span>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="config.showNeutralReviewButton">
                                        <div
                                            class="d-flex justify-content-center button small gray margin-right-5"
                                            [ngClass]="{
                                                selected: review.currentStatus === reviewStatusEnumType.Pending,
                                                'not-selected':
                                                    review.currentStatus !== reviewStatusEnumType.Pending &&
                                                    review.currentStatus !== reviewStatusEnumType.Sanitized
                                            }"
                                            (click)="review.currentStatus = reviewStatusEnumType.Pending; updatePendingNumbers()"
                                        >
                                            <div class="button-content align-self-center">
                                                <span class="icon"><fa-icon [icon]="faAdjust"></fa-icon></span
                                                ><span class="icon-text">ONBESLIST</span>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="config.showApproveReviewButton">
                                        <div
                                            class="d-flex justify-content-center button small blue"
                                            [ngClass]="{
                                                selected: review.currentStatus === reviewStatusEnumType.Accepted,
                                                'not-selected':
                                                    review.currentStatus !== reviewStatusEnumType.Accepted &&
                                                    review.currentStatus !== reviewStatusEnumType.Pending &&
                                                    review.currentStatus !== reviewStatusEnumType.Sanitized
                                            }"
                                            (click)="review.currentStatus = reviewStatusEnumType.Accepted; updatePendingNumbers()"
                                        >
                                            <div class="button-content align-self-center">
                                                <span class="icon"><fa-icon [icon]="faThumbsUp"></fa-icon></span
                                                ><span class="icon-text">GOEDKEUREN</span>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="config.showRejectAndSubmitButton">
                                        <div
                                            class="d-flex justify-content-center button small red"
                                            (click)="rejectReview(review.id)"
                                            *ngIf="
                                                config.showRejectAndSubmitButton && review.currentStatus !== reviewStatusEnumType.Rejected
                                            "
                                        >
                                            <div
                                                class="button-content align-self-center"
                                                *ngIf="
                                                    config.showRejectAndSubmitButton &&
                                                    review.currentStatus === reviewStatusEnumType.Accepted
                                                "
                                            >
                                                <span class="icon"><fa-icon [icon]="faBan"></fa-icon></span
                                                ><span class="icon-text">TERUGTREKKEN</span>
                                            </div>

                                            <div
                                                class="button-content align-self-center"
                                                *ngIf="
                                                    config.showRejectAndSubmitButton &&
                                                    review.currentStatus !== reviewStatusEnumType.Accepted
                                                "
                                            >
                                                <span class="icon"><fa-icon [icon]="faThumbsDown"></fa-icon></span
                                                ><span class="icon-text">AFKEUREN</span>
                                            </div>
                                        </div>

                                        <div
                                            class="d-flex justify-content-center button small blue"
                                            (click)="acceptReview(review.id)"
                                            *ngIf="
                                                config.showRejectAndSubmitButton && review.currentStatus !== reviewStatusEnumType.Accepted
                                            "
                                        >
                                            <div class="button-content align-self-center">
                                                <span class="icon"><fa-icon [icon]="faThumbsUp"></fa-icon></span
                                                ><span class="icon-text">GOEDKEUREN</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="row">
        <div class="col-12">
            <div class="d-flex justify-content-end" (click)="updateReviewStatuses()">
                <ng-container *ngIf="config.showSubmitAllReviewStatusesButton">
                    <div class="d-flex justify-content-center button small green margin-top-10">
                        <div class="button-content align-self-center">
                            <span class="icon"><fa-icon [icon]="faCheckDouble"></fa-icon></span><span class="icon-text">DOORVOEREN</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="row pagination">
    <div class="col-12">
        <div class="d-flex justify-content-center">
            <pagination
                [boundaryLinks]="true"
                previousText="&lsaquo;"
                nextText="&rsaquo;"
                firstText="&laquo;"
                lastText="&raquo;"
                [(ngModel)]="currentPage"
                itemsPerPage="{{ itemsPerPage.value }}"
                totalItems="{{ getManagementReviewsResponse.reviewCount }}"
                [maxSize]="7"
                (pageChanged)="pageChanged($event)"
                class="pagination-sm exp-pagination"
            ></pagination>
        </div>
        <div class="d-flex justify-content-center pagination-description">{{ createPaginationSubText() }}</div>
    </div>

    <div class="col-12 dropdown">
        <div d-flex justify-content-center>
            <app-dropdown
                [values]="config.itemsPerPageValues"
                [title]="'Aantal reviews per pagina'"
                [selectedValue]="itemsPerPage"
                (onSelect)="updateItemsPerPageCount($event)"
            ></app-dropdown>
        </div>
    </div>
</div>
