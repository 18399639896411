<div class="row remove-margin">
    <div class="col-12 remove-padding filter-titel" (click)="dataFiltersCollapsed = !dataFiltersCollapsed">
        DATA FILTERS
        <span *ngIf="!dataFiltersCollapsed" class="icon"><fa-icon [icon]="faAngleDown"></fa-icon></span>
        <span *ngIf="dataFiltersCollapsed" class="icon"><fa-icon [icon]="faAngleUp"></fa-icon></span>
    </div>
</div>

<ng-container *ngIf="!dataFiltersCollapsed">
    <div class="remove-margin search-container">
        <div class="col-12 remove-padding">
            <div class="search-field">
                <input type="text" [(ngModel)]="searchTerm" (keyup)="searchOnInput()" />
                <span class="icon"><fa-icon [icon]="faSearch"></fa-icon></span>
            </div>
        </div>

        <div class="col-12 remove-padding filter-list">
            <div class="align-items-start search-results">
                <div
                    class="col-12 remove-margin d-flex select-box align-items-center"
                    *ngFor="let data of getDataTypeAheadResult"
                    (click)="selectDataFilter(data)"
                >
                    <div class="selected-data">
                        <div class="col-12">
                            <div class="selected-data-item" title="{{ data.displayName }}">
                                <div class="selected-data-item-name">{{ data.displayName }}</div>
                                <span class="lvl">lvl {{ data.level }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row remove-margin">
        <div class="col-12 remove-padding filter-list">
            <div class="row remove-margin selected-data">
                <div class="col-12 remove-padding selected-data-title">GESELECTEERDE DATA</div>
                <div class="col-12 remove-margin d-flex select-box align-items-center" *ngFor="let selectedData of listOfSelectedData">
                    <div class="selected-data">
                        <div class="col-12">
                            <div class="selected-data-item" title="{{ selectedData.displayName }}">
                                <div class="selected-data-item-name">{{ selectedData.displayName }}</div>
                                <span class="lvl">lvl {{ selectedData.level }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row remove-margin">
        <div class="col-12 button-container">
            <span title="Alles verwijderen" class="icon" (click)="clearFilters()"><fa-icon [icon]="faBan"></fa-icon></span>
            <span title="Laatste selectie verwijderen" class="icon" (click)="removeLastFilters()"><fa-icon [icon]="faUndo"></fa-icon></span>
        </div>
    </div>
</ng-container>
