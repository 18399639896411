import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';

import { ScreenSize } from '../../models/screen-size.enum';
import { VideoSize } from '../../models/video-size.model';

@Injectable()
export class Utilities {
    private showSpinner = new BehaviorSubject(false);

    public getScreenSize(): ScreenSize {
        if (this.isBrowser()) {
            const screenWidth: number = window.screen.width;

            if (screenWidth <= 576) {
                return ScreenSize.Xs;
            }

            if (screenWidth <= 768 && screenWidth > 576) {
                return ScreenSize.Sm;
            }

            if (screenWidth <= 991 && screenWidth > 768) {
                return ScreenSize.Md;
            }

            if (screenWidth <= 1200 && screenWidth > 991) {
                return ScreenSize.Lg;
            }

            if (screenWidth > 1200) {
                return ScreenSize.Xl;
            }
        }

        return ScreenSize.NotBrowser;
    }

    public getVideoSize(modifier: number): VideoSize {
        const baseVideoSize: VideoSize = { width: 300, height: 170 };

        if (this.isBrowser()) {
            let screenWidth: number = window.screen.width;

            if (screenWidth >= 768 && screenWidth < 1200) {
                screenWidth = screenWidth * 0.5;
            }

            if (screenWidth >= 1200 && screenWidth < 1600) {
                screenWidth = screenWidth * 0.35;
            }

            if (screenWidth >= 1600) {
                screenWidth = 1600 * 0.35;
            }

            screenWidth = screenWidth * modifier;

            return { width: screenWidth, height: (screenWidth * baseVideoSize.height) / baseVideoSize.width };
        }

        return baseVideoSize;
    }

    public isBrowser(): boolean {
        return typeof window !== 'undefined';
    }

    public getShowSpinnerObservable(): BehaviorSubject<boolean> {
        return this.showSpinner;
    }

    public getShowSpinnerValue(): boolean {
        return this.showSpinner.value;
    }

    public setShowSpinner(showSpinner: boolean): void {
        this.showSpinner.next(showSpinner);
    }

    public createPaginationSubText(selectedPage: number, itemsPerPage: number, arrayLength: number, itemCount: number): string {
        return `${(selectedPage - 1) * itemsPerPage + 1}
                t/m
                ${(selectedPage - 1) * itemsPerPage + arrayLength}
                van de
                ${itemCount}`;
    }

    public isNullOrUndefined(object: any): boolean {
        if (object === null || object === undefined) {
            return true;
        }

        return false;
    }
}
