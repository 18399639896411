import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { IconDefinition, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

import { NgxSpinnerService } from 'ngx-spinner';

import { LinkService } from './shared/services/link.service';
import { Utilities } from './shared/services/utilities.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    public isIframe = window !== window.parent && !window.opener;
    public faTimes: IconDefinition = faTimes;

    private endPageTitle = 'Klantenthousiasme - Reviews';
    private defaultPageTitle = 'Klantenthousiasme';
    private routerSub$: Subscription;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private title: Title,
        private meta: Meta,
        private linkService: LinkService,
        private utils: Utilities,
        private spinner: NgxSpinnerService,
        public translate: TranslateService
    ) {
        translate.setDefaultLang('nl');
        translate.use('nl');
    }

    public ngOnInit(): void {
        this.changeTitleOnNavigation();
        this.subScribeToSpinner();
        this.scrollToTopOnNavigation();
    }

    public ngOnDestroy(): void {
        this.routerSub$.unsubscribe();
    }

    public subScribeToSpinner(): void {
        this.utils
            .getShowSpinnerObservable()
            .pipe(distinctUntilChanged())
            .subscribe((showSpinner) => {
                if (showSpinner) {
                    this.showSpinner();
                } else {
                    this.hideSpinner();
                }
            });
    }

    public hideSpinner(): void {
        if (this.utils.isBrowser()) {
            this.spinner.hide('main');
        }
    }

    private changeTitleOnNavigation(): void {
        this.routerSub$ = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)
            )
            .subscribe((event) => {
                this.setMetaAndLinks(event);
            });
    }

    private setMetaAndLinks(event: any): void {
        const title = event.title ? `${event.title} - ${this.endPageTitle}` : `${this.defaultPageTitle} - ${this.endPageTitle}`;

        this.title.setTitle(title);

        const metaData = event.meta || [];
        const linksData = event.links || [];

        metaData.forEach((md: any) => this.meta.updateTag(md));
        linksData.forEach((ld: any) => this.linkService.addTag(ld));
    }

    private scrollToTopOnNavigation(): void {
        if (this.utils.isBrowser()) {
            this.router.events.subscribe((evt) => {
                if (!(evt instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
        }
    }

    private showSpinner(): void {
        if (this.utils.isBrowser()) {
            this.spinner.show('main');
        }
    }
}
