import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { TranslateModule } from '@ngx-translate/core';

import { NgClickOutsideDirective } from 'ng-click-outside2';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { AccordionComponent } from './accordion/accordion.component';
import { CompanyBlockComponent } from './company-block/company-block.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DataFilterComponent } from './filter-menu/data-filter/data-filter.component';
import { DateFilterComponent } from './filter-menu/date-filter/date-filter.component';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { SortFilterComponent } from './filter-menu/sort-filter/sort-filter.component';
import { StatusFilterComponent } from './filter-menu/status-filter/status-filter.component';
import { TextFilterComponent } from './filter-menu/text-filter/text-filter.component';
import { ImageComponent } from './image/image.component';
import { DataViewTypeTotext } from './pipes/dataviewtype-to-text.pipe';
import { ReviewManagementListComponent } from './review-management-list/review-management-list.component';
import { DataService } from './services/data.service';
import { ImageService } from './services/image.service';
import { ReviewService } from './services/review.service';
import { UserService } from './services/user.service';
import { Utilities } from './services/utilities.service';
import { WidgetService } from './services/widget.service';
import { StarsComponent } from './stars/stars.component';

@NgModule({
    declarations: [
        AccordionComponent,
        CompanyBlockComponent,
        ConfirmationModalComponent,
        DataFilterComponent,
        DataViewTypeTotext,
        DateFilterComponent,
        DropdownComponent,
        FilterMenuComponent,
        ImageComponent,
        ReviewManagementListComponent,
        SortFilterComponent,
        StarsComponent,
        StatusFilterComponent,
        TextFilterComponent
    ],
    imports: [
        BsDatepickerModule.forRoot(),
        NgClickOutsideDirective,
        CollapseModule.forRoot(),
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        ModalModule.forRoot(),
        NgxJsonLdModule,
        PaginationModule.forRoot(),
        ReactiveFormsModule,
        RouterModule,
        TranslateModule.forChild()
    ],
    providers: [ImageService, DataService, Utilities, ReviewService, UserService, WidgetService],
    bootstrap: [],
    exports: [
        AccordionComponent,
        BsDatepickerModule,
        CommonModule,
        CompanyBlockComponent,
        DataFilterComponent,
        DataViewTypeTotext,
        DateFilterComponent,
        DropdownComponent,
        FilterMenuComponent,
        FontAwesomeModule,
        FormsModule,
        ImageComponent,
        NgScrollbarModule,
        NgxJsonLdModule,
        PaginationModule,
        ReactiveFormsModule,
        ReviewManagementListComponent,
        RouterModule,
        SortFilterComponent,
        StarsComponent,
        StatusFilterComponent,
        TextFilterComponent,
        TranslateModule,
        TypeaheadModule
    ]
})
export class SharedModule {}
