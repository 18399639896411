import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi, withXsrfConfiguration } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NavMenuComponent } from './layout/nav-menu/nav-menu.component';
import { HttpRedirectInterceptor } from './shared/services/http-interceptor.service';
import { LinkService } from './shared/services/link.service';
import { SharedModule } from './shared/shared.module';

export function createTranslateLoader(http: HttpClient): any {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    declarations: [AppComponent, NavMenuComponent, FooterComponent],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        NgClickOutsideDirective,
        CommonModule,
        FontAwesomeModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: environment.azureClientId,
                    authority: 'https://login.microsoftonline.com/common',
                    redirectUri: `${window.location.origin}`,
                    navigateToLoginRequestUrl: false
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE
                }
            }),
            {
                interactionType: InteractionType.Redirect,
                authRequest: {
                    scopes: ['user.read', 'api://klantenthousiasme/access']
                }
            },
            {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new Map([
                    [`/api/*Management`, ['api://klantenthousiasme/access']],
                    ['/api/Review/Export', ['api://klantenthousiasme/access']]
                ])
            }
        ),
        NgxSpinnerModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        LinkService,
        MsalGuard,
        TranslateModule,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRedirectInterceptor,
            multi: true
        },
        provideHttpClient(
            withInterceptorsFromDi(),
            withXsrfConfiguration({
                cookieName: 'ExpointsReviews.X-XSRF-TOKEN-COOKIE',
                headerName: 'X-XSRF-TOKEN'
            })
        )
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
