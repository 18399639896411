import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CreateImageModel } from '../../models/create-image.model';
import { GetImagesRequest } from '../../models/get-images-request.model';
import { GetImagesResponse } from '../../models/get-images-response.model';
import { ImageInformation } from '../../models/image-information.model';
import { ImageType } from '../../models/image-type.enum';
import { KeyValuePair } from '../../models/key-value-pair.model';

@Injectable()
export class ImageService {
    private baseUrl: string;
    private imageUrlName = 'api/Image/GetImageByName';
    private imageUrlId = 'api/Image/GetImageById';
    private getImagesResponse = new BehaviorSubject(new GetImagesResponse());
    private getImagesRequest = new BehaviorSubject(new GetImagesRequest('', 0, 1));

    constructor(private http: HttpClient) {
        this.baseUrl = '/';
        this.subscribeToGetImagesRequest();
    }

    public getImageUrlbyName(value: string, valueType: string): string {
        const funcUrl = valueType === 'id' ? this.imageUrlId : this.imageUrlName;
        const url = `${this.baseUrl}${funcUrl}?${valueType}=${value}`;

        return url;
    }

    public getListOfImageInformation(searchString: string, imageType: ImageType, take: number): Observable<ImageInformation[]> {
        const funcUrl = 'api/ImageManagement/GetListOfImageInformation';
        const url = `${this.baseUrl}${funcUrl}`;

        let httpParams = new HttpParams();

        httpParams = httpParams.append('searchString', searchString);
        httpParams = httpParams.append('imageType', imageType.toString());
        httpParams = httpParams.append('take', take.toString());

        return this.http.get<ImageInformation[]>(url, { params: httpParams });
    }

    public setGetImagesRequest(getImagesRequest: GetImagesRequest): void {
        this.getImagesRequest.next(getImagesRequest);
    }

    public getImages(): BehaviorSubject<GetImagesResponse> {
        return this.getImagesResponse;
    }

    public getImagesRequestObservable(): Observable<GetImagesRequest> {
        return this.getImagesRequest;
    }

    public getImagesRequestValue(): GetImagesRequest {
        return this.getImagesRequest.value;
    }

    public deleteImage(imageId: number): void {
        const midUrl = 'api/ImageManagement/DeleteImage';
        const url = `${this.baseUrl}${midUrl}`;

        this.http.post(url, imageId).subscribe(() => {
            this.setGetImagesRequest(this.getImagesRequestValue());
        });
    }

    public getListOfImageTypes(): KeyValuePair[] {
        const listOfImageTypes: KeyValuePair[] = [
            { key: 'Banner', value: ImageType.Banner },
            { key: 'Content', value: ImageType.Content },
            { key: 'DataBrand', value: ImageType.DataBrand }
        ];

        return listOfImageTypes;
    }

    public createImage(createImageModel: CreateImageModel): void {
        const formData: FormData = new FormData();

        formData.append('displayName', createImageModel.displayName);
        formData.append('imageType', createImageModel.imageType.toString());
        formData.append('width', createImageModel.width.toString());
        formData.append('height', createImageModel.height.toString());
        formData.append('base64Image', createImageModel.base64Image);
        formData.append('file', createImageModel.image);

        const midUrl = 'api/ImageManagement/CreateImage';
        const url = `${this.baseUrl}${midUrl}`;

        this.http.post(url, formData).subscribe(() => {
            this.setGetImagesRequest(this.getImagesRequestValue());
        });
    }

    public updateImage(id: number, image: File, base64Image: string, width: number, height: number): void {
        const formData: FormData = new FormData();

        formData.append('id', id.toString());
        formData.append('width', width.toString());
        formData.append('height', height.toString());
        formData.append('base64Image', base64Image);
        formData.append('file', image);

        const midUrl = 'api/ImageManagement/UpdateImage';
        const url = `${this.baseUrl}${midUrl}`;
        this.http.post(url, formData).subscribe(() => {
            this.setGetImagesRequest(this.getImagesRequestValue());
        });
    }

    private subscribeToGetImagesRequest(): void {
        this.getImagesRequest.subscribe((getImagesRequest) => {
            if (getImagesRequest.itemsPerPage !== 0) {
                this.getImagesHttpCall(getImagesRequest);
            }
        });
    }

    private getImagesHttpCall(getImagesRequest: GetImagesRequest): void {
        const midUrl = 'api/ImageManagement/GetImages';
        const url = `${this.baseUrl}${midUrl}?searchTerm=${getImagesRequest.searchTerm}&itemsPerPage=${getImagesRequest.itemsPerPage}&page=${getImagesRequest.page}`;

        this.http.get<GetImagesResponse>(url).subscribe((images) => {
            this.getImagesResponse.next(images);
        });
    }
}
