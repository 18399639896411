<div class="modal-header flex-wrap">
    <div class="red-modal-bar">
        <button type="button" class="close pull-right modal-close" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-title-container">
        <h4 class="modal-title pull-left">{{ title }}</h4>
    </div>
</div>

<div class="modal-body">
    {{ question }}
    <div *ngIf="showDeleteUserError" class="form-error">Er is iets foutgegaan bij het verwijderen van de gebruiker.</div>
</div>

<div class="modal-footer">
    <div class="d-flex justify-content-center button small red" (click)="decline()">
        <div class="button-content align-self-center">
            <span class="icon"><fa-icon [icon]="faBan"></fa-icon></span>
            {{ answerNegative }}
        </div>
    </div>

    <div class="d-flex justify-content-center button small blue" (click)="confirm()">
        <div class="button-content align-self-center">
            <span class="icon"><fa-icon [icon]="faCheck"></fa-icon></span>
            {{ answerPositive }}
        </div>
    </div>
</div>
