<div class="row remove-margin">
    <div class="col-12 remove-padding filter-titel" (click)="dateFiltersCollapsed = !dateFiltersCollapsed">
        DATUM ZOEKEN
        <span *ngIf="!dateFiltersCollapsed" class="icon"><fa-icon [icon]="faAngleDown"></fa-icon></span>
        <span *ngIf="dateFiltersCollapsed" class="icon"><fa-icon [icon]="faAngleUp"></fa-icon></span>
    </div>
</div>

<ng-container *ngIf="!dateFiltersCollapsed">
    <div class="remove-margin search-container">
        <div class="col-12 remove-padding input-label" (click)="dp1.toggle()">
            Van: <span class="float-right">{{ from | date: 'dd-MM-yyyy' }}</span>
            <span class="date-field">
                <input
                    type="text"
                    placeholder="Van"
                    class="form-control"
                    [bsConfig]="bsConfig"
                    (bsValueChange)="onFromValueChange($event)"
                    #dp1="bsDatepicker"
                    bsDatepicker
                />
            </span>

            <span *ngIf="from !== null && from !== undefined" class="icon" (click)="clearFromDate($event)"
                ><fa-icon [icon]="faBan"></fa-icon
            ></span>
        </div>

        <div class="col-12 remove-padding input-label" (click)="dp2.toggle()">
            Tot: <span class="float-right">{{ to | date: 'dd-MM-yyyy' }}</span>
            <span class="date-field">
                <input
                    type="text"
                    placeholder="Tot"
                    class="form-control"
                    [bsConfig]="bsConfig"
                    (bsValueChange)="onToValueChange($event)"
                    #dp2="bsDatepicker"
                    bsDatepicker
                />
            </span>

            <span *ngIf="to !== null && to !== undefined" class="icon" (click)="clearToDate($event)"
                ><fa-icon [icon]="faBan"></fa-icon
            ></span>
        </div>
    </div>
</ng-container>
