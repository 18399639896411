<div class="accordion-container">
    <div class="row">
        <div class="col-12 title" (click)="open = !open" [ngClass]="{ active: open }">
            {{ title }}
            <span *ngIf="open" class="select-icon"><fa-icon [icon]="faAngleDown"></fa-icon></span>
            <span *ngIf="!open" class="select-icon"><fa-icon [icon]="faAngleUp"></fa-icon></span>
        </div>

        <div class="col-12 content" *ngIf="open" [@enterAnimation] [innerHTML]="infoHtml"></div>
    </div>
</div>
