import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DataViewType } from '../../models/data-view-type.enum';
import { Data } from '../../models/data.model';
import { GetDataListRequest } from '../../models/get-data-list-request.model';
import { GetDataListResponse } from '../../models/get-data-list-response.model';
import { GetDataTypeAheadRequest } from '../../models/get-data-type-ahead-request.model';
import { GetDataTypeAheadResult } from '../../models/get-data-type-ahead-result.model';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { SchemaType } from '../../models/schema-type.enum';
import { UpdateDataRequest } from '../../models/update-data.model';

@Injectable()
export class DataService {
    private baseUrl: string;
    private debounceTimeInMilliseconds = 500;
    private getDataTypeAheadResponse = new BehaviorSubject(new Array<GetDataTypeAheadResult>());
    private getDataTypeAheadRequest = new BehaviorSubject(new GetDataTypeAheadRequest());
    private getDataResponse = new BehaviorSubject(new GetDataListResponse());
    private getDataRequest = new BehaviorSubject(new GetDataListRequest('', 0, 1, false, null));

    constructor(private http: HttpClient) {
        this.baseUrl = '/';

        this.subscribeToGetDataTypeAheadRequest();
        this.subscribeToGetDataListRequest();
    }

    public getDataById(value: number): Observable<Data> {
        const midUrl = 'api/Data/GetDataById';
        const url = `${this.baseUrl}${midUrl}?id=${value}`;

        return this.http.get<Data>(url);
    }

    public getDataByDataLvl(value: number): Observable<Data[]> {
        const midUrl = 'api/Data/GetDataByLevel';
        const url = `${this.baseUrl}${midUrl}?level=${value}`;

        return this.http.get<Data[]>(url);
    }

    public getDataByParentId(value: number): Observable<Data[]> {
        const midUrl = 'api/Data/GetDataByParentId';
        const url = `${this.baseUrl}${midUrl}?parentId=${value}`;

        return this.http.get<Data[]>(url);
    }

    public getDataHierarchyById(value: number): Observable<Data> {
        const midUrl = 'api/Data/GetDataHierarchyById';
        const url = `${this.baseUrl}${midUrl}?dataId=${value}`;

        return this.http.get<Data>(url);
    }

    public setGetDataTypeAheadRequest(getDataTypeAheadRequest: GetDataTypeAheadRequest): void {
        this.getDataTypeAheadRequest.next(getDataTypeAheadRequest);
    }

    public getDataTypeAheadRequestValue(): GetDataTypeAheadRequest {
        return this.getDataTypeAheadRequest.value;
    }

    public getDataTypeAheadRequestObservable(): BehaviorSubject<GetDataTypeAheadRequest> {
        return this.getDataTypeAheadRequest;
    }

    public getDataTypeAheadResponseSubscription(): BehaviorSubject<GetDataTypeAheadResult[]> {
        return this.getDataTypeAheadResponse;
    }

    public setDataListRequest(getDataListRequest: GetDataListRequest): void {
        this.getDataRequest.next(getDataListRequest);
    }

    public getDataListRequestValue(): GetDataListRequest {
        return this.getDataRequest.value;
    }

    public getDataListObservable(): BehaviorSubject<GetDataListResponse> {
        return this.getDataResponse;
    }

    public createSeoSchema(data: Data): any {
        return {
            '@context': 'http://schema.org',
            '@type': data.schemaType,
            name: data.value,
            description: data.htmlInfo,
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: data.averageRating,
                reviewCount: data.reviewCount
            }
        };
    }

    public subscribeToGetDataListRequest(): void {
        this.getDataRequest.pipe(debounceTime(this.debounceTimeInMilliseconds)).subscribe((getDataRequest) => {
            if (getDataRequest.page > 0 && getDataRequest.itemsPerPage > 0) {
                this.getDataList(getDataRequest);
            }
        });
    }

    public getListOfSchemaTypes(): KeyValuePair[] {
        const listOfSchemaTypes: KeyValuePair[] = [
            { key: 'Organization', value: SchemaType.Organization },
            { key: 'Product', value: SchemaType.Product },
            { key: 'Service', value: SchemaType.Service },
            { key: 'Brand', value: SchemaType.Brand },
            { key: 'Review', value: SchemaType.Review },
            { key: 'Thing', value: SchemaType.Thing }
        ];

        return listOfSchemaTypes;
    }

    public getListOfViewTypes(): KeyValuePair[] {
        const listOfViewTypes: KeyValuePair[] = [
            { key: 'Blok weergave', value: DataViewType.Block },
            { key: 'Detail weergave', value: DataViewType.Filter }
        ];

        return listOfViewTypes;
    }

    public updateData(updateDataRequest: UpdateDataRequest): Observable<boolean> {
        const midUrl = 'api/DataManagement/UpdateData';
        const url = `${this.baseUrl}${midUrl}`;

        return this.http.post<boolean>(url, updateDataRequest);
    }

    private getDataTypeAheadResponseHttpCall(getDataTypeAheadRequest: GetDataTypeAheadRequest): void {
        const midUrl = 'api/DataManagement/GetDataTypeAheadResult';

        let searchString = '';
        let levelFilterString = '';
        let parentId = '';

        const maxResult = '';

        if (getDataTypeAheadRequest.levelFilter != null && getDataTypeAheadRequest.levelFilter != undefined) {
            levelFilterString = `&levelFilter=${getDataTypeAheadRequest.levelFilter}`;
        }

        if (getDataTypeAheadRequest.parentId != null && getDataTypeAheadRequest.parentId != undefined) {
            parentId = `&parentId=${getDataTypeAheadRequest.parentId}`;
        }

        if (getDataTypeAheadRequest.maxResult != null && getDataTypeAheadRequest.maxResult != undefined) {
            parentId = `&maxResult=${getDataTypeAheadRequest.maxResult}`;
        }

        if (
            getDataTypeAheadRequest.searchString != null &&
            getDataTypeAheadRequest.searchString != undefined &&
            getDataTypeAheadRequest.searchString != ''
        ) {
            searchString = `&searchString=${getDataTypeAheadRequest.searchString}`;
        } else {
            searchString = `&searchString=`;
        }

        const url = `${this.baseUrl}${midUrl}?${searchString}${levelFilterString}${parentId}${maxResult}`;

        this.http.get<GetDataTypeAheadResult[]>(url).subscribe((getDataTypeAheadResult) => {
            this.getDataTypeAheadResponse.next(getDataTypeAheadResult);
        });
    }

    private subscribeToGetDataTypeAheadRequest(): void {
        this.getDataTypeAheadRequest.pipe(debounceTime(500)).subscribe((getDataTypeAheadRequest) => {
            if (getDataTypeAheadRequest.levelFilter == null || getDataTypeAheadRequest.levelFilter == undefined) {
                return;
            }
            this.getDataTypeAheadResponseHttpCall(getDataTypeAheadRequest);
        });
    }

    private getDataList(getDataListRequest: GetDataListRequest): void {
        const midUrl = 'api/DataManagement/GetDataList';
        const url = `${this.baseUrl}${midUrl}`;
        let httpParams = new HttpParams();

        httpParams = httpParams.append('searchValue', getDataListRequest.searchTerm);
        httpParams = httpParams.append('itemsPerPage', getDataListRequest.itemsPerPage.toString());
        httpParams = httpParams.append('page', getDataListRequest.page.toString());
        httpParams = httpParams.append('filterByLevel', getDataListRequest.filterByLevel.toString());

        if (getDataListRequest.parentId !== null) {
            httpParams = httpParams.append('parentId', getDataListRequest.parentId.toString());
        }

        this.http.get<GetDataListResponse>(url, { params: httpParams }).subscribe((getDataListResponse) => {
            this.getDataResponse.next(getDataListResponse);
        });
    }
}
