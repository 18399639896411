<div class="company-block-container {{ class }}" *ngIf="data !== undefined">
    <ngx-json-ld [json]="data.schema"></ngx-json-ld>

    <div class="company-block-image-container">
        <app-image class="company-block-image" [value]="data.brandImgId" [valueType]="'id'"></app-image>
    </div>

    <div class="company-block-bar"></div>

    <div class="company-block-name">{{ data.displayName }}</div>

    <app-stars [min]="data.minRating" [max]="data.maxRating" [rating]="data.averageRating" [size]="'big'"></app-stars>

    <div class="company-block-rating-count">{{ data.reviewCount }} {{ 'SHARED.COMPANY_BLOCK.REVIEW_COUNT' | translate }}</div>

    <ng-container *ngIf="data.viewType === dataViewType.Block">
        <a class="company-block-review-link" [routerLink]="['/reviews/block']" [queryParams]="{ parentid: data.id }">{{
            'SHARED.COMPANY_BLOCK.REVIEW_LINK' | translate: { value: data.displayName }
        }}</a>
    </ng-container>

    <ng-container *ngIf="data.viewType === dataViewType.Filter">
        <a class="company-block-review-link" [routerLink]="['/reviews/detail']" [queryParams]="{ dataId: data.id }">{{
            'SHARED.COMPANY_BLOCK.REVIEW_LINK' | translate: { value: data.displayName }
        }}</a>
    </ng-container>
</div>
