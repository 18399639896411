import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { CreateOrUpdateUserRequest } from '../../models/create-or-update-user-request.model';
import { GetUsersRequest } from '../../models/get-users-request.model';
import { GetUsersResponse } from '../../models/get-users-response.model';
import { NavigationInformation } from '../../models/navigation-info.model';
import { RoleSelect } from '../../models/role-select.model';
import { Role } from '../../models/role.model';
import { UserInformation } from '../../models/user-information.model';

@Injectable()
export class UserService {
    private baseUrl: string;
    private getUsersResponse = new BehaviorSubject(new GetUsersResponse());
    private getUsersRequest = new BehaviorSubject(new GetUsersRequest('', 0, 1));
    private managementMenu = new BehaviorSubject(new Array<NavigationInformation>());
    private roles = new BehaviorSubject(new Array<Role>());

    constructor(private http: HttpClient) {
        this.baseUrl = '/';
        this.subscribeTogetUsersRequest();
    }

    public setGetUsersRequest(getUsersRequest: GetUsersRequest): void {
        this.getUsersRequest.next(getUsersRequest);
    }

    public getUsers(): BehaviorSubject<GetUsersResponse> {
        return this.getUsersResponse;
    }

    public getUsersRequestObservable(): Observable<GetUsersRequest> {
        return this.getUsersRequest;
    }

    public getUsersRequestValue(): GetUsersRequest {
        return this.getUsersRequest.value;
    }

    public getManagementMenuHttpCall(): void {
        const midUrl = 'api/UserManagement/GetManagementMenu';
        const url = `${this.baseUrl}${midUrl}`;

        this.http.get<NavigationInformation[]>(url).subscribe((navigationInformation) => {
            this.managementMenu.next(navigationInformation);
        });
    }

    public getManagementMenu(): BehaviorSubject<NavigationInformation[]> {
        return this.managementMenu;
    }

    public getRolesHttpCall(): void {
        const midUrl = 'api/UserManagement/GetRoles';
        const url = `${this.baseUrl}${midUrl}`;

        this.http.get<RoleSelect[]>(url).subscribe((roles) => {
            this.roles.next(roles);
        });
    }

    public getRoles(): BehaviorSubject<Role[]> {
        return this.roles;
    }

    public createUser(createOrUpdateUserRequest: CreateOrUpdateUserRequest): Observable<UserInformation> {
        const midUrl = 'api/UserManagement/CreateUser';
        const url = `${this.baseUrl}${midUrl}`;

        return this.http.post<UserInformation>(url, createOrUpdateUserRequest);
    }

    public updateUser(createOrUpdateUserRequest: CreateOrUpdateUserRequest): Observable<UserInformation> {
        const midUrl = 'api/UserManagement/UpdateUser';
        const url = `${this.baseUrl}${midUrl}`;

        return this.http.post<UserInformation>(url, createOrUpdateUserRequest);
    }

    public archiveUser(createOrUpdateUserRequest: CreateOrUpdateUserRequest): Observable<UserInformation> {
        const midUrl = 'api/UserManagement/ArchiveUser';
        const url = `${this.baseUrl}${midUrl}`;

        return this.http.post<UserInformation>(url, createOrUpdateUserRequest);
    }

    public mapUserFormToCreateOrUpdateUserRequest(formObject: any, roles: RoleSelect[]): CreateOrUpdateUserRequest {
        const createOrUpdateUserRequest: CreateOrUpdateUserRequest = new CreateOrUpdateUserRequest();

        for (const prop in formObject) {
            if (!Object.prototype.hasOwnProperty.call(formObject, prop)) {
                continue;
            }

            if (typeof formObject[prop] === 'string' && prop === 'username') {
                createOrUpdateUserRequest.name = formObject[prop];
            }

            if (typeof formObject[prop] === 'boolean' && formObject[prop] === true) {
                const roleSelect = roles.find((r) => r.name === prop);
                createOrUpdateUserRequest.roleIds.push(roleSelect.id);
            }
        }

        return createOrUpdateUserRequest;
    }

    public toUserFormGroup(username: string, roles: RoleSelect[]): UntypedFormGroup {
        const group: any = {};

        group.username = new UntypedFormControl(username, Validators.required);

        roles.forEach((role) => {
            group[role.name] = new UntypedFormControl(role.selected);
        });

        const formGroup = new UntypedFormGroup(group);

        return formGroup;
    }

    public userHasOneOrMoreRoles(createOrUpdateUserRequest: CreateOrUpdateUserRequest): boolean {
        if (createOrUpdateUserRequest.roleIds.length < 1) {
            return false;
        }

        return true;
    }

    public mapUserInfoToCreateOrUpdateUserRequest(userInformation: UserInformation): CreateOrUpdateUserRequest {
        const createOrUpdateUserRequest: CreateOrUpdateUserRequest = new CreateOrUpdateUserRequest();

        createOrUpdateUserRequest.name = userInformation.name;

        userInformation.roles.forEach((r) => {
            createOrUpdateUserRequest.roleIds.push(r.id);
        });

        return createOrUpdateUserRequest;
    }

    private subscribeTogetUsersRequest(): void {
        this.getUsersRequest.subscribe((gur) => {
            if (gur.itemsPerPage !== 0) {
                this.getUsersHttpCall(gur);
            }
        });
    }

    private getUsersHttpCall(getUsersRequest: GetUsersRequest): void {
        const midUrl = 'api/UserManagement/GetUsers';
        const url = `${this.baseUrl}${midUrl}?searchTerm=${getUsersRequest.searchTerm}&itemsPerPage=${getUsersRequest.itemsPerPage}&page=${getUsersRequest.page}`;

        this.http.get<GetUsersResponse>(url).subscribe((users) => {
            this.getUsersResponse.next(users);
        });
    }
}
