import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';

import { CreateOrUpdateWidgetRequest } from '../../models/create-or-update-widget-request.model';
import { GetWidgetsRequest } from '../../models/get-widgets-request.model';
import { GetWidgetsResponse } from '../../models/get-widgets-response.model';
import { KeyValuePair } from '../../models/key-value-pair.model';
import { WidgetTypeEnum } from '../../models/widget-type-enum.model';
import { Widget } from '../../models/widget.model';

@Injectable()
export class WidgetService {
    private baseUrl: string;
    private debounceTimeInMilliseconds = 1000;
    private getWidgetsResponse = new BehaviorSubject(new GetWidgetsResponse());
    private getWidgetsRequest = new BehaviorSubject(new GetWidgetsRequest('', 0, 1));
    private previewWidgetsResponse = new BehaviorSubject('');
    private previewWidgetsRequest = new BehaviorSubject(new CreateOrUpdateWidgetRequest());

    constructor(
        private http: HttpClient,
        private spinner: NgxSpinnerService
    ) {
        this.baseUrl = '/';
        this.subscribeTogetWidgetsRequest();
        this.subscribeTogetPreviewWidgetsRequest();
    }

    public setPreviewWidgetsRequest(createOrUpdateWidgetRequest: CreateOrUpdateWidgetRequest): void {
        this.previewWidgetsRequest.next(createOrUpdateWidgetRequest);
    }

    public getPreviewWidgetsResponseObservable(): BehaviorSubject<string> {
        return this.previewWidgetsResponse;
    }

    public clearPreviewWidgetsResponse(): void {
        this.previewWidgetsResponse.next('');
    }

    public setGetWidgetsRequest(getWidgetsRequest: GetWidgetsRequest): void {
        this.getWidgetsRequest.next(getWidgetsRequest);
    }

    public getWidgets(): BehaviorSubject<GetWidgetsResponse> {
        return this.getWidgetsResponse;
    }

    public getWidgetsRequestObservable(): Observable<GetWidgetsRequest> {
        return this.getWidgetsRequest;
    }

    public getWidgetsRequestValue(): GetWidgetsRequest {
        return this.getWidgetsRequest.value;
    }

    public archiveWidget(widgetId: number): Observable<Widget> {
        const midUrl = 'api/WidgetManagement/ArchiveWidget';
        const url = `${this.baseUrl}${midUrl}`;

        return this.http.post<Widget>(url, widgetId);
    }

    public getListOfWidgetTypes(): KeyValuePair[] {
        const listOfWidgetTypes: KeyValuePair[] = [
            { key: 'Type 1', value: WidgetTypeEnum.Type1 },
            { key: 'Type 2', value: WidgetTypeEnum.Type2 },
            { key: 'Type 3', value: WidgetTypeEnum.Type3 },
            { key: 'Type 4', value: WidgetTypeEnum.Type4 }
        ];

        return listOfWidgetTypes;
    }

    public getListOfWidgetSubTypes(): KeyValuePair[] {
        const listOfWidgetTypes: KeyValuePair[] = [
            { key: 'Normaal', value: '' },
            { key: 'Breed', value: '-fluid' },
            { key: 'Smal', value: '-vertical' }
        ];

        return listOfWidgetTypes;
    }

    public getListOfReviewAmounts(): KeyValuePair[] {
        const listOfWidgetTypes: KeyValuePair[] = [
            { key: '1', value: 1 },
            { key: '2', value: 2 },
            { key: '3', value: 3 },
            { key: '4', value: 4 },
            { key: '5', value: 5 },
            { key: '6', value: 6 },
            { key: '7', value: 7 },
            { key: '8', value: 8 },
            { key: '9', value: 9 },
            { key: '10', value: 10 }
        ];

        return listOfWidgetTypes;
    }

    public getListOfFontTypes(): KeyValuePair[] {
        const listOfWidgetTypes: KeyValuePair[] = [
            { key: 'Arial', value: 'Arial, "sans-serif"' },
            { key: 'Helvetica', value: 'Helvetica, "sans-serif"' },
            { key: 'Times New Roman', value: 'Times New Roman, "sans-serif"' },
            { key: 'Times', value: 'Times, "sans-serif"' },
            { key: 'Courier New', value: 'Courier New, "sans-serif"' },
            { key: 'Courier', value: 'Courier, "sans-serif"' },
            { key: 'Verdana', value: 'Verdana, "sans-serif"' },
            { key: 'Georgia', value: 'Georgia, "sans-serif"' },
            { key: 'Palatino', value: 'Palatino, "sans-serif"' },
            { key: 'Garamond', value: 'Garamond, "sans-serif"' },
            { key: 'Bookman', value: 'Bookman, "sans-serif"' },
            { key: 'Comic Sans MS', value: 'Comic Sans MS, "sans-serif"' },
            { key: 'Trebuchet MS', value: 'Trebuchet MS, "sans-serif"' },
            { key: 'Arial Black', value: 'Arial Black, "sans-serif"' },
            { key: 'Impact', value: 'Impact, "sans-serif"' },
            { key: 'Ubuntu', value: 'Ubuntu, "sans-serif"' }
        ];

        return listOfWidgetTypes;
    }

    public createWidget(createOrUpdateWidgetRequest: CreateOrUpdateWidgetRequest): void {
        const midUrl = 'api/WidgetManagement/CreateWidget';
        const url = `${this.baseUrl}${midUrl}`;

        this.http.post(url, createOrUpdateWidgetRequest).subscribe(() => {
            this.setGetWidgetsRequest(this.getWidgetsRequestValue());
        });
    }

    public previewWidget(createOrUpdateWidgetRequest: CreateOrUpdateWidgetRequest): void {
        const midUrl = 'api/WidgetManagement/PreviewWidget';
        const url = `${this.baseUrl}${midUrl}`;

        this.spinner.show('cuw');

        this.http.post(url, createOrUpdateWidgetRequest, { responseType: 'text' }).subscribe((success) => {
            this.spinner.hide('cuw');
            this.previewWidgetsResponse.next(success);
        });
    }

    public getWidget(key: string): Observable<any> {
        const midUrl = 'api/Widget/GetWidget';
        const url = `${this.baseUrl}${midUrl}?key=${key}`;

        return this.http.get(url, { responseType: 'text' });
    }

    private getImagesHttpCall(getWidgetsRequest: GetWidgetsRequest): void {
        const midUrl = 'api/WidgetManagement/GetWidgets';
        const url = `${this.baseUrl}${midUrl}?searchTerm=${getWidgetsRequest.searchTerm}&itemsPerPage=${getWidgetsRequest.itemsPerPage}&page=${getWidgetsRequest.page}`;

        this.http.get<GetWidgetsResponse>(url).subscribe((widgets) => {
            this.getWidgetsResponse.next(widgets);
        });
    }

    private subscribeTogetWidgetsRequest(): void {
        this.getWidgetsRequest.subscribe((getWidgetsRequest) => {
            if (getWidgetsRequest.itemsPerPage !== 0) {
                this.getImagesHttpCall(getWidgetsRequest);
            }
        });
    }

    private subscribeTogetPreviewWidgetsRequest(): void {
        this.previewWidgetsRequest.pipe(debounceTime(this.debounceTimeInMilliseconds)).subscribe((createWidgetRequest) => {
            if (createWidgetRequest.dataId !== null && createWidgetRequest.dataId !== undefined) {
                this.previewWidget(createWidgetRequest);
            }
        });
    }
}
