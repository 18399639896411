<div class="row remove-margin">
    <div class="col-12 remove-padding filter-titel" (click)="statusFiltersCollapsed = !statusFiltersCollapsed">
        STATUS OPTIES
        <span *ngIf="!statusFiltersCollapsed" class="icon"><fa-icon [icon]="faAngleDown"></fa-icon></span>
        <span *ngIf="statusFiltersCollapsed" class="icon"><fa-icon [icon]="faAngleUp"></fa-icon></span>
    </div>
</div>

<ng-container *ngIf="!statusFiltersCollapsed">
    <div *ngFor="let statusOption of statusOptions">
        <input
            class="form-check-input radio"
            type="radio"
            id="{{ statusOption.name }}"
            [value]="statusOption.type"
            name="statusOption"
            [(ngModel)]="selectedStatusOption"
            (click)="updateStatusOptions(statusOption.type)"
        />
        <label class="form-check-label" for="{{ statusOption.name }}">{{ statusOption.name }}</label>
    </div>
</ng-container>
